import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular/standalone';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { ErrorService } from '@services/error.service';
import { DeviceService } from '@shared/services/device.service';
// import debug from 'debug';

declare var APP_VERSION: string;

// const log = debug('cs:AppErrorHandler');

const filterMessageList = [
  "Messaging: This browser doesn't support the API's required to use the firebase SDK. (messaging/unsupported-browser).",
];

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler implements ErrorHandler {
  private lastError;

  constructor(
    private errorService: ErrorService //
  ) {}

  private _preventThrottling(err) {
    return (
      !this.lastError ||
      (this.lastError.err !== err && Date.now() - this.lastError.date > 30000)
    ); // avoid sending duplicate errors in short time
  }

  async handleError(err: Error) {
    if (!err?.message || filterMessageList.includes(err.message)) {
      // log('skipping error', err);
      return;
    }

    if (environment.errorLog && this._preventThrottling(err)) {
      // handling error when app can't write to local database
      if (err.message && err.message.includes('QuotaExceededError')) {
        alert(
          'We noticed a problem with processing data. It is recommended that you restart your browser or the application.'
        );
      }

      this.lastError = {
        err: err,
        date: Date.now(),
      };
      this.errorService.postError(err.message, err.stack);
    }
    throw err;
  }
}
