import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IState, IUser } from '@models';
import { Store } from '@ngrx/store';
import * as userSelectors from '@store/selectors/user';
import { filter, first, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectedUserProfileResolver {
  constructor(private store: Store<IState>) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IUser> {
    console.log('ConnectedUserProfileResolver');
    return (
      this.store
        .select(userSelectors.selectAuthenticatedIuser)
        // .pipe(filter((user) => !!user.UserId));
        .pipe(first((user) => !!user.UserId))
    );
  }
}
