import { LayoutModule } from '@angular/cdk/layout';
import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { ThemeDetection } from '@awesome-cordova-plugins/theme-detection/ngx';
import { BarcodeFormat as NativeBarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { Platform, provideIonicAngular } from '@ionic/angular/standalone';
import { PWATutorialModal } from '@modals/pwa-tuto-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ScannerModule } from '@services/scanner/scanner.module';
import { SharedComponentsModule } from '@shared/components';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { SharedPipesModule } from '@shared/pipes';
import { DeviceService } from '@shared/services/device.service';
import { AppErrorHandler } from '@shared/services/error.handler';
import { SharedModule } from '@shared/shared.module';
import { RootEffects } from '@store/effects';
import { metaReducers, reducers } from '@store/reducers';
import { addIcons } from 'ionicons';
import { chevronDownOutline } from 'ionicons/icons';
import { ImgFallbackModule } from 'ngx-img-fallback';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    LayoutComponent,
    RouterModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    SharedModule,
    SharedPipesModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(RootEffects),
    LayoutModule,
    ...environment.imports,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ScannerModule.forRoot({
      proposeToInstallGoogleNativeScanner: false,
      webFormats: [
        // 'code_128_reader',
        'ean_reader',
        // 'ean_5_reader',
        // 'ean_2_reader',
        // 'ean_8_reader',
        // 'code_39_reader',
        // 'code_39_vin_reader',
        // 'codabar_reader',
        'upc_reader',
        'upc_e_reader',
        // 'i2of5_reader',
        // '2of5_reader',
        // 'code_93_reader',
        // 'code_32_reader',
      ],
      nativeFormats: [
        // NativeBarcodeFormat.Aztec,
        // NativeBarcodeFormat.Codabar,
        // NativeBarcodeFormat.Code39,
        // NativeBarcodeFormat.Code128,
        // NativeBarcodeFormat.DataMatrix,
        // NativeBarcodeFormat.Ean8,
        NativeBarcodeFormat.Ean13,
        // NativeBarcodeFormat.Itf,
        // NativeBarcodeFormat.Pdf417,
        NativeBarcodeFormat.UpcA,
        NativeBarcodeFormat.UpcE,
      ],
      nativeFallbackActiveClass: 'barcode-scanner-active',
      nativeFallbackClass: 'native-scanner-modal',
    }),
    ImgFallbackModule,
    PWATutorialModal,
  ],
  providers: [
    ThemeDetection,
    DecimalPipe,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (deviceService: DeviceService) => () => deviceService.init(),
      deps: [DeviceService],
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: (platform: Platform) => ({
        enabled: !platform.is('hybrid') && environment.production,
      }),
      deps: [Platform],
    },
    provideIonicAngular({
      innerHTMLTemplatesEnabled: true,
      swipeBackEnabled: true,
      refreshingIcon: 'chevron-down-outline',
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // used by provideIonicAngular
    addIcons({ chevronDownOutline });
  }
}
