import { Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import debug from 'debug';
import { Subject } from 'rxjs';

const log = debug('cs:OnlineService');

@Injectable({ providedIn: 'root' })
export class OnlineService {
  change = new Subject<
    | {
        type: 'networkStatusChange';
        isOnline: boolean;
        extra: ConnectionStatus;
      }
    | {
        type: 'visibilitychange';
        isOnline: boolean;
      }
  >();

  constructor() {
    log('init');
    this.change.subscribe((data) => log(data));
    Network.addListener('networkStatusChange', (status) => {
      log('networkStatusChange', status);
      this.change.next({
        type: 'networkStatusChange',
        isOnline: status.connected,
        extra: status,
      });
    });

    document.addEventListener('visibilitychange', () => {
      this.change.next({
        type: 'visibilitychange',
        isOnline: !document.hidden,
      });
    });
  }
}
