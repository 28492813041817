import {
  ThemeDetection,
  ThemeDetectionResponse,
} from '@awesome-cordova-plugins/theme-detection/ngx';
import { DOCUMENT } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { Platform } from '@ionic/angular/standalone';
import { Injectable, Inject } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';

import debug from 'debug';

const log = debug('cs:ThemeService');

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    public platform: Platform,
    private mediaMatcher: MediaMatcher,
    private themeDetection: ThemeDetection
  ) {}

  async init() {
    const isDark = await this.isDark();
    this.toggleDarkTheme(isDark);
    this.startListeners();
  }

  startListeners() {
    if (this.platform.is('capacitor')) {
      this.platform.resume.subscribe(async () => {
        const isDark = await this.isNativeDark();
        log('****RESUMED****', { isDark });
        this.toggleDarkTheme(isDark);
        StatusBar.setStyle({
          style: isDark ? Style.Dark : Style.Light,
        });
        StatusBar.setOverlaysWebView({
          overlay: false,
        });
      });
    } else {
      const prefersDark = this.mediaMatcher.matchMedia(
        '(prefers-color-scheme: dark)'
      );
      prefersDark.addListener((mediaQuery) => {
        this.toggleDarkTheme(mediaQuery.matches);
      });
    }
  }

  async isDark() {
    if (this.platform.is('capacitor')) {
      return await this.isNativeDark();
    } else {
      const prefersDark = this.mediaMatcher.matchMedia(
        '(prefers-color-scheme: dark)'
      );
      return prefersDark.matches;
    }
  }

  private async isNativeDark() {
    try {
      const isAvailable = await this.themeDetection
        .isAvailable()
        .then((res: ThemeDetectionResponse) => res.value);

      log('isNativeDark', { isAvailable });
      if (isAvailable) {
        const { value } = await this.themeDetection.isDarkModeEnabled();
        log('isDarkModeEnabled', { value });
        return value;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  toggleDarkTheme(isDark) {
    const $body = this.doc.querySelector('body');
    const $ionRoot = this.doc.querySelector('ion-app');
    log('toggleDarkTheme', { isDark });
    if (isDark) {
      $body.classList.add('dark-theme');
      $ionRoot.classList.add('dark-theme');
    } else {
      $body.classList.remove('dark-theme');
      $ionRoot.classList.remove('dark-theme');
    }
    if (this.platform.is('capacitor')) {
      const style = isDark ? Style.Dark : Style.Light;
      log('StatusBar', { style });
      StatusBar.setStyle({ style });
      StatusBar.setBackgroundColor({ color: isDark ? '#000000' : '#ffffff' });
      StatusBar.setOverlaysWebView({ overlay: false });
    }
  }
}
