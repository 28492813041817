import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular/standalone';
import { IState, IUserSettings } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AccountService } from '@services/account.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { FacebookAuthService } from '@shared/services/auth/facebook-auth.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SocketHandlerService } from '@shared/services/socket-handler.service';
import { StorageService } from '@shared/services/storage.service';
import * as notificationsActions from '@store/actions/notifications';
import * as userActions from '@store/actions/user';
import * as userSelectors from '@store/selectors/user';
import { AppRoutes } from '@utils/routes';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  finalize,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class UserEffects {
  constructor(
    private store: Store<IState>,
    private router: Router,
    private readonly actions$: Actions<userActions.ActionsUnion>,
    private userService: UserService,
    private accountService: AccountService,
    private pushNotificationsService: PushNotificationsService,
    private toastService: ToastService,
    private socketHandlerService: SocketHandlerService,
    private localStorageService: LocalStorageService,
    private facebookAuthService: FacebookAuthService,
    private storageService: StorageService,
    private analyticsService: AnalyticsService,
    private loadingCtrl: LoadingController,
    private navController: NavController
  ) {}

  unauthorized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.Unauthorized),
      debounceTime(500), // prevents several http 401 on app start with old token for instance
      mergeMap(() => {
        return of(userActions.LogoutRequest());
      })
    )
  );

  requestSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.SummaryRequest),
      switchMap(() =>
        this.userService.getDataSummary().pipe(
          map((summary) => userActions.SummarySuccess({ summary })),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        )
      )
    )
  );

  agreeToTrems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.AgreeToTermsRequest),
      switchMap(() =>
        this.userService.agreeToTerms().pipe(
          map(() => userActions.AgreeToTermsSuccess()),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        )
      )
    )
  );

  requestSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.SettingsRequest),
      withLatestFrom(this.store.select(userSelectors.selectSettings)),
      switchMap(([action, settings]) => {
        if (settings !== null) {
          return of(userActions.SettingsSuccess({ settings }));
        }
        return this.userService.getSettings().pipe(
          map((response) =>
            userActions.SettingsSuccess({ settings: response })
          ),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        );
      })
    )
  );

  requestAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.AccountRequest),
      switchMap((action) => {
        return this.accountService.getAccount().pipe(
          map((account) => {
            this.socketHandlerService.createConnection(account.Id);
            return userActions.AccountSuccess({ account });
          }),
          catchError((er: HttpErrorResponse) => {
            this.socketHandlerService.createConnection();
            return of(userActions.UserError());
          })
        );
      })
    )
  );

  updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.AccountUpdateRequest),
      switchMap(({ form }) => {
        const loading = this.loadingCtrl.create({
          message: 'Updating profile...',
          backdropDismiss: false,
        });
        loading.then((el) => el.present());
        return this.accountService.update(form).pipe(
          map((account) => {
            this.toastService.show('Profile updated successfully');
            this.navController.navigateBack(AppRoutes.profile());
            return userActions.AccountSuccess({ account });
          }),
          catchError((er: HttpErrorResponse) =>
            of(userActions.AccountUpdateError())
          ),
          finalize(() => {
            loading.then((el) => el.dismiss());
          })
        );
      })
    )
  );

  updateAccountPic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.AccountUpdatePicRequest),
      switchMap(({ image }) => {
        const loading = this.loadingCtrl.create({
          message: 'Updating profile pic...',
          backdropDismiss: false,
        });
        loading.then((el) => el.present());
        return this.accountService.uploadProfilePicture(image).pipe(
          mergeMap((url) => this.accountService.changeProfilePicture(url)),
          map((account) => {
            this.toastService.show('Profile pic updated successfully');
            return userActions.AccountSuccess({ account });
          }),
          catchError((er: HttpErrorResponse) =>
            of(userActions.AccountUpdateError())
          ),
          finalize(() => {
            loading.then((el) => el.dismiss());
          })
        );
      })
    )
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.SettingsUpdateRequest),
      withLatestFrom(this.store.select(userSelectors.selectSettings)),
      switchMap(([{ settings }, oldSettings]) => {
        if (detectNotificationsChange(oldSettings, settings)) {
          this.pushNotificationsService.askForPermission();
        }
        return this.userService.updateSettings(settings).pipe(
          map((response) =>
            userActions.SettingsUpdateSuccess({ settings: response })
          ),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        );
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.UserDeleteRequest),
      switchMap(() => {
        return this.accountService.deleteUser().pipe(
          map(() => {
            this.toastService.show('Your account has been deleted');
            return userActions.UserDeleteSuccess();
          }),
          catchError((er: HttpErrorResponse) => of(userActions.UserError()))
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LogoutRequest),
      switchMap(() => {
        this.socketHandlerService.stop();
        return this.accountService.logout().pipe(
          map(() => userActions.LogoutSuccess()),
          catchError((er: HttpErrorResponse) => {
            if (er.status === 401) {
              // if logout failed because of wrong token, remove token anyway
              return of(userActions.LogoutSuccess());
            }
            return of(userActions.UserError());
          })
        );
      })
    )
  );

  logoutAfterAccountDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.UserDeleteSuccess),
      map(() => userActions.LogoutRequest())
    )
  );

  requestRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.RegisterRequest),
      switchMap(({ form }) => {
        this.analyticsService.register();
        this.socketHandlerService.stop();
        const loading = this.loadingCtrl.create({
          message: 'Registering...',
          backdropDismiss: false,
        });
        loading.then((el) => el.present());
        return this.accountService.register(form).pipe(
          map((account) => {
            return userActions.LoginSuccess({ account });
          }),
          catchError((er: HttpErrorResponse) => of(userActions.UserError())),
          finalize(() => {
            loading.then((el) => el.dismiss());
          })
        );
      })
    )
  );

  requestLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LoginRequest),
      switchMap(({ form }) => {
        this.socketHandlerService.stop();
        const loading = this.loadingCtrl.create({
          message: 'Logging in...',
          backdropDismiss: false,
        });
        loading.then((el) => el.present());
        return this.accountService.login(form).pipe(
          map((account) => {
            return userActions.LoginSuccess({ account });
          }),
          catchError((er: HttpErrorResponse) => of(userActions.UserError())),
          finalize(() => {
            loading.then((el) => el.dismiss());
          })
        );
      })
    )
  );

  requestProdiverLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LoginProviderRequest),
      switchMap(({ form }) => {
        this.socketHandlerService.stop();
        const loading = this.loadingCtrl.create({
          message: 'Logging in...',
          backdropDismiss: false,
        });
        loading.then((el) => el.present());
        return this.accountService.loginViaProvider(form).pipe(
          map((account) => {
            return userActions.LoginSuccess({ account });
          }),
          catchError((er: HttpErrorResponse) => of(userActions.UserError())),
          finalize(() => {
            loading.then((el) => el.dismiss());
          })
        );
      })
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.LoginSuccess),
      switchMap(({ account }) => {
        this.pushNotificationsService.register();
        this.socketHandlerService.start();
        this.router.navigateByUrl(AppRoutes.myCigars());
        this.localStorageService.set(
          'authToken',
          account.Authorization.AccessToken
        );
        return [
          userActions.AccountSuccess({ account }),
          userActions.SummaryRequest(),
          notificationsActions.NotificationsHeadRequest(),
        ];
      })
    )
  );

  logoutRedirection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.LogoutSuccess),
        tap(async () => {
          this.facebookAuthService.logout();
          await this.localStorageService.remove('authToken');
          this.socketHandlerService.start();
          this.pushNotificationsService.unregister();
          this.storageService.clear();
          this.store.dispatch(userActions.SummaryRequest());
          this.router.navigateByUrl(AppRoutes.login());
        })
      ),
    { dispatch: false }
  );
}

function detectNotificationsChange(
  oldSettings: IUserSettings,
  newSettings: Partial<IUserSettings>
) {
  return (
    (newSettings.NotifyAboutLikes && !oldSettings.NotifyAboutLikes) ||
    (newSettings.NotifyAboutNewFollowers &&
      !oldSettings.NotifyAboutNewFollowers) ||
    (newSettings.NotifyAboutComments && !oldSettings.NotifyAboutComments)
  );
}
