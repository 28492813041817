import { Location } from '@angular/common';
import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { AlertController, Platform } from '@ionic/angular/standalone';
import { GuestRegistrationModal } from '@modals/guest-registration-modal.component';
import { IState } from '@models';
import { select, Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { NetworkService } from '@services/network.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { ThemeService } from '@services/theme.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { CanonicalUrlService } from '@shared/services/canonical-url.service';
import { DeviceService } from '@shared/services/device.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { PWAService } from '@shared/services/pwa.service';
import { SocketHandlerService } from '@shared/services/socket-handler.service';
import { TitleService } from '@shared/services/title.service';
import * as notificationsActions from '@store/actions/notifications';
import * as userActions from '@store/actions/user';
import * as userSelectors from '@store/selectors/user';
import { AppRoutes } from '@utils/routes';
import { PWATutorialModal } from 'app/modals/pwa-tuto-modal.component';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import { filter, tap } from 'rxjs';
import { register } from 'swiper/element/bundle';

// import { SurveyAnswerModel } from '@shared/models/survey-answer.model';
// import { SurveyResponseModel } from '@shared/models/survey-response.model';
// import { SurveyModel } from '@shared/models/survey.model';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  routes = AppRoutes;

  isActiveGuest$ = this.store.pipe(select(userSelectors.selectIsActiveGuest));
  constructor(
    private platform: Platform,
    private pushNotificationsService: PushNotificationsService,
    private titleService: TitleService,
    private canonicalUrlService: CanonicalUrlService,
    private analyticsService: AnalyticsService,
    private location: Location,
    private router: Router,
    private localStorageService: LocalStorageService,
    private socketHandlerService: SocketHandlerService,
    private pwaService: PWAService,
    private deviceService: DeviceService,
    private modalService: ModalService,
    private alertController: AlertController,
    private store: Store<IState>,
    private zone: NgZone,
    private networkService: NetworkService,
    private themeService: ThemeService // private surveyService: SurveyService
  ) {
    this.platform.ready().then(() => this.initializeApp());
  }

  async initializeApp() {
    if (!this.location.path()) {
      const defaultRoute = await this.localStorageService.get('defaultRoute');
      if (defaultRoute) {
        this.router.navigateByUrl(defaultRoute);
      }
    }

    // Deeplinking
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        try {
          const slug = new URL(event.url).pathname;
          if (slug) {
            this.router.navigateByUrl(slug);
          }
        } catch (error) {}
      });
    });

    this.socketHandlerService.start();
    this.store.dispatch(userActions.SummaryRequest());
    this.store.dispatch(userActions.SettingsRequest());
    this.store.dispatch(notificationsActions.NotificationsHeadRequest());
    this.themeService.init();
    this.analyticsService.init();
    this.pwaService.init();
    this.canonicalUrlService.init();
    this.titleService.setTitles();
    this.displayPWAInstallTutorial();
    this.displayGuestRegistration();
    this.pushNotificationsService.init();
    this.networkService.init();

    if (this.platform.is('capacitor')) {
      SplashScreen.hide();
      // comment that until we can easily test it
      // if (this.platform.is('android')) {
      //   this.checkForSurveys();
      // }
    }

    this.platform.backButton.subscribeWithPriority(1, async () => {
      const element = await this.modalService.getTop();
      // console.log(
      //   'back button pressed',
      //   element,
      //   this.router.routerState.snapshot.url,
      //   this.routes.myCigars()
      // );
      if (element) {
        element.dismiss();
        return;
      }
      if (this.router.routerState.snapshot.url === this.routes.myCigars()) {
        App.exitApp();
      }
      this.location.back();
    });

    // draw keyboard settings
    // this.config.set('scrollPadding', false);
    // this.config.set('scrollAssist', this.platform.is('android'));

    // this.router.events
    //   .pipe(filter((val) => val instanceof NavigationEnd))
    //   .subscribe((route: NavigationEnd) => {
    //     // for now we only call on android since the only survey we have is rate the app
    //     // not necessary on PWA
    //     if (this.platform.is('android')) {
    //       if (
    //         [
    //           this.routes.myCigars(),
    //           this.routes.social(),
    //           this.routes.tools(),
    //         ].includes(route.url)
    //       ) {
    //       }
    //     }
    //   });
  }

  // async checkForSurveys() {
  //   const survey = await this.surveyService.getSurveys().toPromise();
  //   if (survey) {
  //     const modal = await this.modalController.create({
  //       component: SurveyModal,
  //       backdropDismiss: false,
  //       componentProps: {
  //         survey: survey,
  //       },
  //     });
  //     await modal.present();
  //   }
  // }
  async displayGuestRegistration() {
    this.isActiveGuest$
      .pipe(
        filter((isActiveGuest) => !!isActiveGuest),
        tap(async (isActiveGuest) => {
          try {
            const date = await this.localStorageService.get(
              'guestRegistration'
            );
            const diffInWeeks = differenceInWeeks(new Date(), new Date(date));
            if (diffInWeeks <= 0) return;
            // if already seen in the session we skip
            // this.analyticsService.trackEvent('Guest Registration', 'Open Modal');
            const modal = await this.modalService.createCSModal(
              GuestRegistrationModal,
              {}
            );
            await modal.present();
            const { role } = await modal.onWillDismiss();
            // this.analyticsService.trackEvent(
            //   'Guest Registration',
            //   `Close Modal: ${role}`
            // );
            this.localStorageService.set(
              'guestRegistration',
              new Date().toISOString()
            );
          } catch (error) {}
        })
      )
      .subscribe();
  }

  async displayPWAInstallTutorial() {
    // if already seen in the session we skip
    if (sessionStorage.getItem('pwainstall')) return;
    // no need to display the tuto if already installed
    if (this.deviceService.isStandalone) return;
    if (
      this.deviceService.isiOSMobile() ||
      this.deviceService.isiOSSafariMobile()
    ) {
      // wait 4s before showing the modal
      await new Promise((r) => setTimeout(r, 4000));
      const alert = await this.alertController.create({
        header: 'Install Cigar Scanner?',
        message:
          'Enjoy Full App Experience by Adding Cigar Scanner to Your Home Screen!',
        buttons: [
          {
            text: 'Not Now',
            role: 'cancel',
            handler: () => {
              sessionStorage.setItem('pwainstall', 'skipped');
              // this.analyticsService.trackEvent(
              //   PWATrackCategory,
              //   'iosinstallpopup',
              //   'refused'
              // );
            },
          },
          {
            text: 'Learn how',
            handler: async () => {
              // this.analyticsService.trackEvent(
              //   PWATrackCategory,
              //   'iosinstallpopup',
              //   'accepted'
              // );
              const modal = await this.modalService.createCSModal(
                PWATutorialModal,
                {
                  browser: this.deviceService.isiOSSafariMobile()
                    ? 'ios-safari'
                    : 'ios-browser',
                }
              );
              await modal.present();
              await modal.onWillDismiss();
              sessionStorage.setItem('pwainstall', 'done');
            },
          },
        ],
      });

      await alert.present();
    }
  }
}
