<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/tabs/my-cigars"></ion-back-button>
    </ion-buttons>
    <ion-title>Settings</ion-title>
    <ion-progress-bar
      type="indeterminate"
      *ngIf="isLoading$ | async"
    ></ion-progress-bar>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="settings-wrapper">
  <ng-container *rxLet="isGuest$; let isGuest">
    <ng-container *rxLet="isAuthenticated$; let isAuthenticated">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Settings</ion-title>
        </ion-toolbar>
      </ion-header>

      <div class="page-container">
        <ion-list [inset]="true" lines="full" *ngIf="isAuthenticated">
          <ion-list-header lines="none">
            <ion-label>Account</ion-label>
          </ion-list-header>
          <ion-item button detail (click)="openPasswordModal()">
            <ion-label>Change Password</ion-label>
          </ion-item>
          <ion-item button detail color="danger" (click)="deleteAlert()">
            <ion-label>Delete My Account</ion-label>
          </ion-item>
        </ion-list>

        <form [formGroup]="formGroup" (ngSubmit)="updateSettings()">
          <ion-list [inset]="true" lines="full">
            <ion-list-header lines="none">
              <ion-label>List Management</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-toggle formControlName="AutoAddScanned">
                <div class="ion-text-wrap">
                  Automatically Add Successful Scan to Journal
                </div>
              </ion-toggle>
            </ion-item>
            <ion-item>
              <ion-toggle formControlName="AutoAddReviewed">
                <div class="ion-text-wrap">
                  Automatically Add Reviews to Journal
                </div>
              </ion-toggle>
            </ion-item>
          </ion-list>
          <ion-list *ngIf="!isGuest" [inset]="true" lines="full">
            <ion-list-header lines="none">
              <ion-label>Social Posts</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-toggle formControlName="AutoPostScans">
                <div class="ion-text-wrap">
                  Automatically Post My Scans To Social Feeds
                </div>
              </ion-toggle>
            </ion-item>
            <ion-item>
              <ion-toggle formControlName="AutoPostReviews">
                <div class="ion-text-wrap">
                  Automatically Post My Reviews To Social Feeds
                </div>
              </ion-toggle>
            </ion-item>
            <ion-item>
              <ion-toggle formControlName="ShareLocation">
                <div class="ion-text-wrap">Share post location</div>
              </ion-toggle>
            </ion-item>
          </ion-list>
          <ion-list [inset]="true" lines="full">
            <ion-list-header>
              <ion-label>Social Feed</ion-label>
            </ion-list-header>
            <ion-item lines="none">
              <ion-toggle formControlName="DisplayAutomaticPosts">
                <div class="ion-text-wrap">
                  Display other users automatic posts (reviews and scans)
                </div>
              </ion-toggle>
            </ion-item>
          </ion-list>
          <ion-list *ngIf="!isGuest" [inset]="true" lines="full">
            <ion-list-header lines="none">
              <ion-label>Notifications</ion-label>
            </ion-list-header>

            <ion-item>
              <ion-toggle formControlName="NotifyAboutLikes">
                <div class="ion-text-wrap">
                  Notify Me if Someone Like My Posts
                </div>
              </ion-toggle>
            </ion-item>
            <ion-item>
              <ion-toggle formControlName="NotifyAboutComments">
                <div class="ion-text-wrap">
                  Notify Me if Someone Comment on my Posts
                </div>
              </ion-toggle>
            </ion-item>

            <ion-item>
              <ion-toggle formControlName="NotifyAboutNewFollowers">
                <div class="ion-text-wrap">
                  Notify Me if Someone starts following me
                </div>
              </ion-toggle>
            </ion-item>
            <ion-item *ngIf="isAuthenticated">
              <ion-toggle formControlName="NotifyWhenTagged">
                <div class="ion-text-wrap">Notify Me if Someone tags me</div>
              </ion-toggle>
            </ion-item>
            <ion-item *ngIf="isAuthenticated">
              <ion-toggle formControlName="SendNotificationsToEmail">
                <div class="ion-text-wrap">Send me email notifications</div>
              </ion-toggle>
            </ion-item>
          </ion-list>
          <ion-list *ngIf="!isGuest" [inset]="true" lines="full">
            <ion-list-header lines="none">
              <ion-label>Privacy Setting</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-select
                formControlName="ShareJournal"
                label="Share my Journal with"
              >
                <ion-select-option *ngFor="let option of dropDownOptions">{{
                  option
                }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-select
                formControlName="ShareFavorites"
                label="Share my Favorites with"
              >
                <ion-select-option *ngFor="let option of dropDownOptions">{{
                  option
                }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-select
                formControlName="ShareWishList"
                label="Share my WishList with"
              >
                <ion-select-option *ngFor="let option of dropDownOptions">{{
                  option
                }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-select
                formControlName="ShareHumidorContent"
                label="Share my Humidors with"
              >
                <ion-select-option *ngFor="let option of dropDownOptions">{{
                  option
                }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-select
                formControlName="ShareHumidorValue"
                label="Share my Humidors Value with"
              >
                <ion-select-option *ngFor="let option of dropDownOptions">{{
                  option
                }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
          <ion-list [inset]="true" lines="full">
            <ion-list-header>
              <ion-label>Start Page</ion-label>
            </ion-list-header>
            <ion-item lines="none">
              <ion-select
                [formControl]="defaultRouteControl"
                label="Set Start Page to"
              >
                <ion-select-option value="tabs/my-cigars"
                  >Journal</ion-select-option
                >
                <ion-select-option value="tabs/social"
                  >Social</ion-select-option
                >
                <ion-select-option value="tabs/my-humidors"
                  >Humidors</ion-select-option
                >
              </ion-select>
            </ion-item>
          </ion-list>
          <ion-list [inset]="true" lines="full">
            <ion-list-header lines="none">
              <ion-label>Temperature Unit</ion-label>
            </ion-list-header>

            <ion-radio-group formControlName="TemperatureUnit">
              <ion-item>
                <ion-radio justify="start" value="F" labelPlacement="end">
                  °F
                </ion-radio>
              </ion-item>

              <ion-item>
                <ion-radio justify="start" value="C" labelPlacement="end">
                  °C
                </ion-radio>
              </ion-item>
            </ion-radio-group>
            <ion-button
              class="ion-hide-sm-down"
              expand="block"
              size="large"
              type="submit"
            >
              Save
            </ion-button>
          </ion-list>
        </form>
      </div>
    </ng-container>
  </ng-container>
</ion-content>

<ion-footer class="ion-no-border ion-hide-sm-up">
  <ion-toolbar>
    <ion-button expand="full" size="large" (click)="updateSettings()"
      >Save</ion-button
    >
  </ion-toolbar>
</ion-footer>
