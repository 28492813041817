import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RxFor } from '@rx-angular/template/for';

import { RunScriptsDirective } from './directives/run-scripts.directive';
import { CigarLogSocketEventHandler } from './services/socket-event-handlers/cigar-log.socket-event-handler';
import { GatewayResponseSocketEventHandler } from './services/socket-event-handlers/gateway-response.socket-event-handler';
import { HumidorInfoSocketEventHandler } from './services/socket-event-handlers/humidor-info.socket-event-handler';
import { HumidorSocketEventHandler } from './services/socket-event-handlers/humidor.socket-event-handler';
import { KnowledgeSocketEventHandler } from './services/socket-event-handlers/knowledge.socket-event-handler';
import { ProductNoteSocketEventHandler } from './services/socket-event-handlers/product-note.socket-event-handler';
import { ProductReviewSocketEventHandler } from './services/socket-event-handlers/product-review.socket-event-handler';
import { ProductSocketEventHandler } from './services/socket-event-handlers/product.socket-event-handler';
import { SensorOfflineSocketEventHandler } from './services/socket-event-handlers/sensor-offline.socket-event-handler';
import { SensorSocketEventHandler } from './services/socket-event-handlers/sensor.socket-event-handler';
import { SocialCommentSocketEventHandler } from './services/socket-event-handlers/social-comment.socket-event-handler';
import { SocialPostSocketEventHandler } from './services/socket-event-handlers/social-post.socket-event-handler';
import { UserSocketEventHandler } from './services/socket-event-handlers/user.socket-event-handler';

// import { SharedPipesModule } from './pipes/index';
@NgModule({
  declarations: [],
  imports: [CommonModule, RxFor],
  providers: [
    CigarLogSocketEventHandler,
    {
      provide: 'CigarLogSocketEventHandler',
      useClass: CigarLogSocketEventHandler,
    },
    GatewayResponseSocketEventHandler,
    {
      provide: 'GatewayResponseSocketEventHandler',
      useClass: GatewayResponseSocketEventHandler,
    },
    HumidorInfoSocketEventHandler,
    {
      provide: 'HumidorInfoSocketEventHandler',
      useClass: HumidorInfoSocketEventHandler,
    },
    HumidorSocketEventHandler,
    {
      provide: 'HumidorSocketEventHandler',
      useClass: HumidorSocketEventHandler,
    },
    KnowledgeSocketEventHandler,
    {
      provide: 'KnowledgeSocketEventHandler',
      useClass: KnowledgeSocketEventHandler,
    },
    ProductNoteSocketEventHandler,
    {
      provide: 'ProductNoteSocketEventHandler',
      useClass: ProductNoteSocketEventHandler,
    },
    ProductReviewSocketEventHandler,
    {
      provide: 'ProductReviewSocketEventHandler',
      useClass: ProductReviewSocketEventHandler,
    },
    ProductSocketEventHandler,
    {
      provide: 'ProductSocketEventHandler',
      useClass: ProductSocketEventHandler,
    },
    SensorOfflineSocketEventHandler,
    {
      provide: 'SensorOfflineSocketEventHandler',
      useClass: SensorOfflineSocketEventHandler,
    },
    SensorSocketEventHandler,
    { provide: 'SensorSocketEventHandler', useClass: SensorSocketEventHandler },
    SocialCommentSocketEventHandler,
    {
      provide: 'SocialCommentSocketEventHandler',
      useClass: SocialCommentSocketEventHandler,
    },
    SocialPostSocketEventHandler,
    {
      provide: 'SocialPostSocketEventHandler',
      useClass: SocialPostSocketEventHandler,
    },
    UserSocketEventHandler,
    { provide: 'UserSocketEventHandler', useClass: UserSocketEventHandler },
  ],
  exports: [RxFor],
})
export class SharedModule {}
