<ion-app [ngClass]="{ mobile: mobile, desktop: !mobile }">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" [swipeGesture]="false" type="push">
      <ng-container *rxLet="account$; let activeUser">
        <ng-container *rxLet="isAuthenticated$; let isUserAuthenticated">
          <ng-container *rxLet="isKiosker$; let isKiosker">
            <ion-content *ngIf="mobile" class="mobile-menu">
              <div class="mobile-menu-wrapper">
                <ion-menu-toggle>
                  <div routerLink="/login" *ngIf="!isUserAuthenticated">
                    <div class="user-logo empty"></div>
                    <div class="user-name empty">Login</div>
                  </div>
                </ion-menu-toggle>
                <div *ngIf="isUserAuthenticated && activeUser">
                  <img
                    *ngIf="activeUser.AvatarUrl"
                    class="user-logo"
                    [src]="activeUser.AvatarUrl | createSource"
                    src-fallback="assets/images/user-image.png"
                  />
                  <div
                    *ngIf="!activeUser.AvatarUrl"
                    class="user-logo empty"
                  ></div>
                  <div class="user-name">
                    {{ activeUser | userName }}
                  </div>
                </div>
                <ion-list>
                  <div *ngIf="isKiosker">
                    <ion-menu-toggle>
                      <ion-item lines="none" routerLink="/microstore">
                        <ion-icon
                          slot="start"
                          name="tv"
                          class="mobile-menu-icon microstore"
                        ></ion-icon>
                        <ion-label>Screen Settings</ion-label>
                      </ion-item>
                    </ion-menu-toggle>
                  </div>
                  <ion-menu-toggle>
                    <ion-item
                      [disabled]="!isUserAuthenticated"
                      lines="none"
                      routerLink="/profile"
                    >
                      <div class="mobile-menu-icon my-profile"></div>
                      <ion-label>My Profile</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                  <ion-menu-toggle>
                    <ion-item lines="none" routerLink="/notifications">
                      <div class="mobile-menu-icon notifications">
                        <div
                          class="number-wrapper new-notifications"
                          *ngIf="unread$ | async as unread"
                        >
                          {{ unread }}
                        </div>
                      </div>
                      <ion-label>Notifications</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                  <ion-menu-toggle>
                    <ion-item lines="none" routerLink="/settings">
                      <ion-icon
                        slot="start"
                        name="settings"
                        class="settings-icon"
                      ></ion-icon>
                      <ion-label>Settings</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                  <ion-menu-toggle
                    *ngIf="platform.is('capacitor') && platform.is('ios')"
                  >
                    <ion-item lines="none" routerLink="/tabs/tools">
                      <ion-icon
                        slot="start"
                        name="hammer"
                        class="hammer-icon"
                      ></ion-icon>
                      <ion-label>Tools</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                  <ion-menu-toggle>
                    <ion-item lines="none" routerLink="/feedback">
                      <div class="mobile-menu-icon feedback"></div>
                      <ion-label>Feedback</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                  <ion-menu-toggle>
                    <ion-item lines="none" routerLink="/about">
                      <div class="mobile-menu-icon about"></div>
                      <ion-label>About</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                  <ion-menu-toggle
                    *ngIf="platform.is('capacitor') && platform.is('android')"
                  >
                    <ion-item lines="none" (click)="rateApp()">
                      <div class="mobile-menu-icon rate"></div>
                      <ion-label>Rate This App</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                  <ion-item
                    lines="none"
                    class="log-out-wrapper"
                    *ngIf="isUserAuthenticated"
                    (click)="logout()"
                  >
                    <div class="mobile-menu-icon log-out"></div>
                    <ion-label>Log Out</ion-label>
                  </ion-item>
                </ion-list>
              </div>
            </ion-content>
            <ion-content *ngIf="!mobile" class="menu-wrapper menu-desktop">
              <ion-list class="ion-no-padding">
                <div class="camera-wrapper">
                  <img
                    class="menu-logo"
                    src="../../assets/images/menu-logo.png"
                  />

                  <div
                    *ngIf="!isUserAuthenticated"
                    routerLink="/login"
                    [routerLinkActive]="['active']"
                  >
                    <div class="user-logo empty"></div>
                    <div class="user-name empty">Login</div>
                  </div>
                  <div *ngIf="isUserAuthenticated">
                    <img
                      *ngIf="activeUser.AvatarUrl"
                      class="user-logo"
                      [src]="activeUser.AvatarUrl"
                    />
                    <div
                      *ngIf="!activeUser.AvatarUrl"
                      class="user-logo empty"
                    ></div>
                    <div class="user-name">
                      {{ activeUser | userName }}
                    </div>
                  </div>
                  <ion-item
                    lines="none"
                    class="scan-button"
                    (click)="takePicture()"
                  >
                    <ion-label>
                      <div class="menu-icon camera"></div>
                      <span>Scan</span>
                    </ion-label>
                  </ion-item>
                  <ion-item
                    lines="none"
                    class="search-button"
                    (click)="openSearch()"
                  >
                    <ion-label>
                      <div class="menu-icon search"></div>
                      <span>Search</span>
                    </ion-label>
                  </ion-item>
                </div>

                <ion-item
                  lines="none"
                  routerLink="/microstore"
                  [routerLinkActive]="['active']"
                  *ngIf="isKiosker"
                >
                  <ion-icon slot="start" name="tv"></ion-icon>
                  <ion-label>Screen Settings</ion-label>
                </ion-item>

                <ion-item
                  lines="none"
                  routerLink="/profile"
                  *ngIf="isUserAuthenticated"
                >
                  <div class="menu-icon my-profile"></div>
                  <ion-label>My Profile</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  routerLink="/tabs/my-cigars"
                  [routerLinkActive]="['active']"
                >
                  <div class="menu-icon my-cigars"></div>
                  <ion-label>My Cigars</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  routerLink="/tabs/social"
                  [routerLinkActive]="['active']"
                >
                  <ion-icon name="share-social"></ion-icon>
                  <ion-label>Social</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  routerLink="/tabs/my-humidors"
                  [routerLinkActive]="['active']"
                >
                  <div class="menu-icon humidors"></div>
                  <ion-label>My Humidors</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  routerLink="/notifications"
                  [routerLinkActive]="['active']"
                >
                  <ion-icon name="notifications"></ion-icon>
                  <ion-label>Notifications</ion-label>
                  <span class="badge" *ngIf="unread$ | async as unread">{{
                    unread
                  }}</span>
                </ion-item>
                <ion-item
                  lines="none"
                  routerLink="/tabs/tools"
                  [routerLinkActive]="['active']"
                >
                  <ion-icon name="hammer"></ion-icon>
                  <ion-label>Tools</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  routerLink="/settings"
                  [routerLinkActive]="['active']"
                  ><ion-icon slot="start" name="settings"></ion-icon>

                  <ion-label>Settings</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  routerLink="/feedback"
                  [routerLinkActive]="['active']"
                >
                  <ion-icon slot="start" name="chatbubbles"></ion-icon>
                  <ion-label>Feedback</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  class="desktop-auth-icon"
                  *ngIf="isUserAuthenticated"
                  (click)="logout()"
                >
                  <div class="menu-icon log-out"></div>
                  <ion-label>Log Out</ion-label>
                </ion-item>
              </ion-list>
              <div routerLink="/privacy-policy" class="privacy-policy">
                Privacy policy
              </div>
            </ion-content>
          </ng-container>
        </ng-container>
      </ng-container>
    </ion-menu>

    <ion-router-outlet id="main-content" [animated]="true"></ion-router-outlet>
  </ion-split-pane>

  <input
    type="file"
    accept="image/png, image/jpeg, image/webp"
    id="capture"
    #file
    name="camera"
    style="visibility: hidden; width: 1px; height: 1px"
  />
</ion-app>
