import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AlertController,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonProgressBar,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { AccountPasswordModal } from '@modals/account-password';
import { IState, IUserSettingsShareList } from '@models';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { ToastService } from '@services/toast.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import * as userActions from '@store/actions/user';
import * as userSelectors from '@store/selectors/user';
import { addIcons } from 'ionicons';
import { trash } from 'ionicons/icons';
import { filter, first } from 'rxjs';

@Component({
  selector: 'settings',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RxLet,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonProgressBar,
    IonContent,
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonToggle,
    IonSelect,
    IonSelectOption,
    IonRadioGroup,
    IonRadio,
    IonButton,
    IonFooter,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonProgressBar,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonListHeader,
    IonNote,
    IonToggle,
    IonIcon,
  ],
  templateUrl: 'settings.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPage implements OnInit {
  dropDownOptions = ['None', 'All', 'Followers'];
  isGuest$ = this.store.pipe(select(userSelectors.selectIsGuest));
  isAuthenticated$ = this.store.pipe(
    select(userSelectors.selectIsAuthenticated)
  );
  isLoading$ = this.store.pipe(select(userSelectors.selectIsLoading));
  settings$ = this.store.pipe(select(userSelectors.selectSettings));
  formGroup = this.fb.nonNullable.group({
    AutoAddScanned: [false, Validators.required],
    AutoAddReviewed: [false, Validators.required],
    AutoPostScans: [false, Validators.required],
    AutoPostReviews: [false, Validators.required],
    DisplayAutomaticPosts: [false, Validators.required],
    NotifyAboutLikes: [false, Validators.required],
    NotifyAboutComments: [false, Validators.required],
    NotifyAboutNewFollowers: [false, Validators.required],
    NotifyWhenTagged: [false, Validators.required],
    SendNotificationsToEmail: [false, Validators.required],
    ShareLocation: [false, Validators.required],
    ShareJournal: this.fb.control<IUserSettingsShareList>(
      'None',
      Validators.required
    ),
    ShareFavorites: this.fb.control<IUserSettingsShareList>(
      'None',
      Validators.required
    ),
    ShareWishList: this.fb.control<IUserSettingsShareList>(
      'None',
      Validators.required
    ),
    ShareHumidorContent: this.fb.control<IUserSettingsShareList>(
      'None',
      Validators.required
    ),
    ShareHumidorValue: this.fb.control<IUserSettingsShareList>(
      'None',
      Validators.required
    ),
    TemperatureUnit: ['', Validators.required],
  });
  defaultRouteControl = this.fb.control('');

  constructor(
    private store: Store<IState>,
    private actions$: Actions,
    private location: Location,
    private toastService: ToastService,
    private alertCtrl: AlertController,
    private localStorageService: LocalStorageService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    addIcons({ trash });
  }

  async ngOnInit() {
    const defaultRoute =
      (await this.localStorageService.get('defaultRoute')) || 'tabs/my-cigars';
    this.defaultRouteControl.setValue(defaultRoute);
  }

  ionViewDidEnter() {
    this.store.dispatch(userActions.SettingsRequest());
    this.settings$
      .pipe(
        filter((value) => !!value),
        first()
      )
      .subscribe((settings) => {
        this.formGroup.patchValue(settings);
        this.cdr.markForCheck();
      });
  }

  updateSettings() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.cdr.markForCheck();
      return;
    }
    this.formGroup.markAsPristine();
    this.cdr.markForCheck();
    this.actions$
      .pipe(ofType(userActions.SettingsUpdateSuccess), first())
      .subscribe(() => {
        this.toastService.show('Saved');
        this.location.back();
      });
    this.store.dispatch(
      userActions.SettingsUpdateRequest({ settings: this.formGroup.value })
    );

    this.localStorageService.set(
      'defaultRoute',
      this.defaultRouteControl.value
    );
  }

  async openPasswordModal() {
    const modal = await this.modalService.createCSModal(
      AccountPasswordModal,
      {},
      {
        mobile: {
          initialBreakpoint: 0.5,
          breakpoints: [0, 0.5, 1],
        },
      }
    );
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'success') {
      this.toastService.show('Password updated successfully');
    }
  }

  async canDeactivate() {
    if (this.formGroup.pristine) return true;

    const alert = await this.alertCtrl.create({
      backdropDismiss: false,
      message:
        'You have unsaved changes, Click Save to save your changes or Continue to exit without saving',
      buttons: [
        {
          text: 'Continue',
        },
        {
          text: 'Save',
          role: 'save',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'save') {
      this.updateSettings();
      return false;
    }
    return true;
  }

  async deleteAlert() {
    const alert = await this.alertCtrl.create({
      backdropDismiss: false,
      message: 'All your data will be erased, are you sure?',
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Yes',
          role: 'delete',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'delete') {
      this.formGroup.markAsUntouched();
      this.cdr.markForCheck();
      this.store.dispatch(userActions.UserDeleteRequest());
      return false;
    }
  }
}
