import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { LoadingController, NavController } from '@ionic/angular/standalone';
import { IState } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocialService } from '@services/social.service';
import { ToastService } from '@services/toast.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import * as socialActions from '@store/actions/social';
import {
  getSocialCollectionId,
  getSocialProductCollectionId,
  getSocialUserCollectionId,
} from '@store/reducers/social';
import * as commentsSelectors from '@store/selectors/comments';
import * as socialSelectors from '@store/selectors/social.selectors';
import { AppRoutes } from '@utils/routes';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class SocialEffects {
  constructor(
    private readonly actions$: Actions<socialActions.ActionsUnion>,
    private socialService: SocialService,
    private analyticsService: AnalyticsService,
    private toastService: ToastService,
    private store: Store<IState>,
    private router: Router,
    private navController: NavController,
    private loadingCtrl: LoadingController,
    private route: ActivatedRoute
  ) {}

  loadPostComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostSuccess),
      map(({ postId }) => {
        return socialActions.SocialPostLoadCommentsRequest({
          postId,
        });
      })
    )
  );

  garbage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        socialActions.SocialPostsToTop,
        socialActions.SocialPostsCleanCollection
      ),
      map(() => {
        return socialActions.SocialPostsGarbageCollection();
      })
    )
  );

  cleanPostComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostRemoveCommentSuccess),
      mergeMap(({ commentId }) =>
        this.store.select(commentsSelectors.selectComment(commentId))
      ),
      map((comment) => {
        if (!comment) return { type: '[No Action]' }; // No action dispatched;
        return socialActions.SocialPostsCleanupComment({
          postId: comment.PostId,
          commentId: comment.Id,
        });
      })
    )
  );

  cleanPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostsGarbageCollection),
      withLatestFrom(
        this.store.select(socialSelectors.selectEntities),
        this.store.select(socialSelectors.selectCollections)
      ),
      map(([action, entities, collections]) => {
        const uniqueExistingIds = Object.keys(collections).reduce(
          (acc, collectionId) => {
            const collection = collections[collectionId];
            // collection.ids can be null if called after a cleanup
            if (!collection || !collection.ids) return acc;
            return [...new Set([...acc, ...collection.ids])];
          },
          [] as number[]
        );

        const idsToDelete = Object.keys(entities).reduce((acc, postId) => {
          if (!uniqueExistingIds.includes(Number(postId))) {
            acc.push(Number(postId));
          }
          return acc;
        }, []);

        return socialActions.SocialPostsCleanup({
          ids: idsToDelete,
        });
      })
    )
  );

  garbageOnPullToRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostsSuccess),
      map(({ skip }) => {
        if (skip === 0) {
          return socialActions.SocialPostsGarbageCollection();
        }
        return { type: '[No Action]' }; // No action dispatched
      })
    )
  );

  requestSocialPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostsRequest),
      mergeMap((action) =>
        combineLatest([
          of(action),
          this.store.select(
            socialSelectors.getSocialSelectors(
              getSocialCollectionId(action.followedOnly)
            ).length
          ),
        ]).pipe(first())
      ),
      switchMap(([{ skip, take, followedOnly }, length]) => {
        const collectionId = getSocialCollectionId(followedOnly);
        skip = skip ?? length;
        return this.socialService.getSocialPosts(skip, followedOnly).pipe(
          map((posts) =>
            socialActions.SocialPostsSuccess({
              collectionId,
              posts,
              skip,
              take: take || environment.pageLimit,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostsError({
                collectionId,
              })
            )
          )
        );
      })
    )
  );
  requestProductPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialProductPostsRequest),
      mergeMap((action) =>
        combineLatest([
          of(action),
          this.store.select(
            socialSelectors.getSocialSelectors(
              getSocialProductCollectionId(action.productId)
            ).length
          ),
        ]).pipe(first())
      ),
      switchMap(([{ skip, take, productId }, length]) => {
        const collectionId = getSocialProductCollectionId(productId);
        skip = skip ?? length;
        return this.socialService.getProductPosts(productId, skip).pipe(
          map((posts) =>
            socialActions.SocialPostsSuccess({
              collectionId,
              posts,
              skip,
              take: take || environment.pageLimit,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostsError({
                collectionId,
              })
            )
          )
        );
      })
    )
  );
  requestUserPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialUserPostsRequest),
      mergeMap((action) =>
        combineLatest([
          of(action),
          this.store.select(
            socialSelectors.getSocialSelectors(
              getSocialUserCollectionId(action.userId)
            ).length
          ),
        ]).pipe(first())
      ),
      switchMap(([{ skip, take, userId, userType }, length]) => {
        const collectionId = getSocialUserCollectionId(userId);
        skip = skip ?? length;
        return this.socialService.getUserPosts(userId, userType, skip).pipe(
          map((posts) =>
            socialActions.SocialPostsSuccess({
              collectionId,
              posts,
              skip,
              take: take || environment.pageLimit,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostsError({
                collectionId,
              })
            )
          )
        );
      })
    )
  );

  getPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostRequest),
      mergeMap(({ postId }) => {
        return this.socialService.getPost(postId).pipe(
          map((post) =>
            socialActions.SocialPostSuccess({
              postId,
              post,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostError({
                postId,
              })
            )
          )
        );
      })
    )
  );

  getPostComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostLoadCommentsRequest),
      mergeMap(({ postId, parentId }) => {
        return this.socialService.getComments(postId, parentId).pipe(
          map((comments) =>
            socialActions.SocialPostLoadCommentsSuccess({
              postId,
              parentId,
              comments,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostLoadCommentsError({
                postId,
                parentId,
              })
            )
          )
        );
      })
    )
  );

  follow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostFollowToggleRequest),
      mergeMap(({ userId, userType, isFollowed }) => {
        return this.socialService
          .followToggle(userType, userId, isFollowed)
          .pipe(
            map(() => {
              if (!isFollowed) {
                this.analyticsService.socialFollow();
              }
              return socialActions.SocialPostFollowToggleSuccess({
                userId,
                userType,
                isFollowed,
              });
            }),
            catchError((er: HttpErrorResponse) =>
              of(
                socialActions.SocialPostFollowToggleError({
                  userId,
                  userType,
                  isFollowed,
                })
              )
            )
          );
      })
    )
  );

  like$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostLikeToggleRequest),
      mergeMap(({ postId, isLiked }) => {
        return this.socialService.likeToggle(postId, isLiked).pipe(
          map((like) => {
            if (!isLiked) {
              this.analyticsService.socialLike();
            }
            return socialActions.SocialPostLikeToggleSuccess({ postId, like });
          }),
          catchError((er: HttpErrorResponse) =>
            of(socialActions.SocialPostLikeToggleError({ postId }))
          )
        );
      })
    )
  );

  createPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostCreateRequest),
      mergeMap(({ post }) => {
        const loading = this.loadingCtrl.create({
          message: 'Creating post...',
          backdropDismiss: false,
        });
        loading.then((el) => el.present());
        return this.socialService.createPost(post).pipe(
          map((newPost) => {
            this.analyticsService.socialPost();
            this.toastService.show(
              'Successfully posted on social feed',
              'short',
              [
                {
                  text: 'Go to Social',
                  handler: () => {
                    this.router.navigateByUrl(AppRoutes.social());
                  },
                },
              ]
            );
            return socialActions.SocialPostCreateSuccess({ post: newPost });
          }),
          catchError((er: HttpErrorResponse) =>
            of(socialActions.SocialPostCreateError())
          ),
          finalize(() => {
            loading.then((el) => el.dismiss());
          })
        );
      })
    )
  );

  editPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostEditRequest),
      mergeMap(({ postId, post }) => {
        return this.socialService.updatePost(postId, post).pipe(
          map((updatedPost) =>
            socialActions.SocialPostEditSuccess({ postId, post: updatedPost })
          ),
          catchError((er: HttpErrorResponse) =>
            of(socialActions.SocialPostEditError({ postId }))
          )
        );
      })
    )
  );

  removePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostRemoveRequest),
      mergeMap(({ postId }) => {
        return this.socialService.removePost(postId).pipe(
          map((newPost) => {
            this.toastService.show('Post removed successfully');
            return socialActions.SocialPostRemoveSuccess({ postId });
          }),
          catchError((er: HttpErrorResponse) =>
            of(socialActions.SocialPostRemoveError({ postId }))
          )
        );
      })
    )
  );

  comment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostAddCommentRequest),
      mergeMap(({ postId, text, commentParentId }) => {
        const loading = this.loadingCtrl.create({
          message: commentParentId ? 'Replying...' : 'Commenting...',
          backdropDismiss: false,
        });
        loading.then((el) => el.present());
        return this.socialService
          .addComment(postId, text, commentParentId)
          .pipe(
            map((comment) => {
              this.analyticsService.socialComment();
              this.toastService.show(
                `Successfully ${commentParentId ? 'replied' : 'commented'}`,
                'short'
              );
              return socialActions.SocialPostAddCommentSuccess({
                postId,
                commentParentId,
                comment,
              });
            }),
            catchError((er: HttpErrorResponse) =>
              of(socialActions.SocialPostAddCommentError({ postId }))
            ),
            finalize(() => {
              loading.then((el) => el.dismiss());
            })
          );
      })
    )
  );

  commentEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostEditCommentRequest),
      mergeMap(({ postId, commentId, text }) => {
        return this.socialService.editComment(postId, commentId, text).pipe(
          map((comment) =>
            socialActions.SocialPostEditCommentSuccess({
              postId,
              commentId,
              comment,
            })
          ),
          catchError((er: HttpErrorResponse) =>
            of(socialActions.SocialPostEditCommentError({ postId, commentId }))
          )
        );
      })
    )
  );

  toggleCommentLike$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostToggleCommentLikeRequest),
      mergeMap(({ postId, commentId, isLiked }) => {
        return this.socialService
          .toggleCommentLike(postId, commentId, isLiked)
          .pipe(
            map((response) =>
              socialActions.SocialPostToggleCommentLikeSuccess({
                postId,
                commentId,
                isLiked: !!response.Liked,
                comment: response.Comment,
              })
            ),
            catchError((er: HttpErrorResponse) =>
              of(
                socialActions.SocialPostToggleCommentLikeError({
                  postId,
                  commentId,
                })
              )
            )
          );
      })
    )
  );

  commentRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostRemoveCommentRequest),
      mergeMap(({ postId, commentId }) => {
        return this.socialService.removeComment(postId, commentId).pipe(
          map(() =>
            socialActions.SocialPostRemoveCommentSuccess({ postId, commentId })
          ),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostRemoveCommentError({ postId, commentId })
            )
          )
        );
      })
    )
  );

  reportUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostReportUserRequest),
      mergeMap(({ userType, userId, reasonId }) => {
        return this.socialService.reportUser(userType, userId, reasonId).pipe(
          map(() => {
            this.toastService.show('User has been reported');
            return socialActions.SocialPostReportUserSuccess({
              userType,
              userId,
              reasonId,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostReportUserError({
                userType,
                userId,
                reasonId,
              })
            )
          )
        );
      })
    )
  );

  reportPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostReportRequest),
      mergeMap(({ postId, reasonId }) => {
        return this.socialService.reportPost(postId, reasonId).pipe(
          map(() => {
            this.toastService.show('Post has been reported');
            return socialActions.SocialPostReportSuccess({
              postId,
              reasonId,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostReportError({
                postId,
                reasonId,
              })
            )
          )
        );
      })
    )
  );

  reportComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialPostReportCommentRequest),
      mergeMap(({ commentId, reasonId }) => {
        return this.socialService.reportComment(commentId, reasonId).pipe(
          map(() => {
            this.toastService.show('Comment has been reported');
            return socialActions.SocialPostReportCommentSuccess({
              commentId,
              reasonId,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialPostReportCommentError({
                commentId,
                reasonId,
              })
            )
          )
        );
      })
    )
  );

  blockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socialActions.SocialBlockUserRequest),
      mergeMap(({ userId, userType }) => {
        return this.socialService.blockUser(userType, userId).pipe(
          map(() => {
            this.toastService.show('User has been blocked');
            return socialActions.SocialBlockUserSuccess({
              userType,
              userId,
            });
          }),
          catchError((er: HttpErrorResponse) =>
            of(
              socialActions.SocialBlockUserError({
                userType,
                userId,
              })
            )
          )
        );
      })
    )
  );
}
