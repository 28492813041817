import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import * as userSelectors from '@store/selectors/user';
import { is } from 'date-fns/locale';
import { first, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private store: Store<IState>) {}

  canActivate(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store
      .select(userSelectors.selectIsAuthenticated)
      .pipe(first((isAuthenticated) => !!isAuthenticated));
  }
}
