import { is } from 'date-fns/locale';
import { Injectable } from '@angular/core';
import { IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import { IComment } from '@shared/models/comment-model';
import * as socialActions from '@store/actions/social';

import { SocketEventHandlerInterface } from './socket-event-handler.interface';

// import { CommentData } from '../../data/comment.data';
// import { IComment } from '../../models/comment-model';
@Injectable()
export class SocialCommentSocketEventHandler
  implements SocketEventHandlerInterface
{
  // constructor(private commentData: CommentData) {}
  constructor(private store: Store<IState>) {}

  created(socketMessage: ISocketParsedMessage<IComment>) {
    const comment = socketMessage.Data;

    this.store.dispatch(
      socialActions.SocialPostAddCommentSuccess({
        postId: comment.PostId,
        comment,
        isFromSocket: true,
      })
    );
  }

  deleted(socketMessage: ISocketParsedMessage<number>) {
    const commentId = socketMessage.Data;

    this.store.dispatch(
      socialActions.SocialPostRemoveCommentSuccess({
        commentId,
      })
    );
  }

  updated(socketMessage: ISocketParsedMessage<IComment>) {
    const comment = socketMessage.Data;

    this.store.dispatch(
      socialActions.SocialPostEditCommentSuccess({
        postId: comment.PostId,
        commentId: comment.Id,
        comment,
        isFromSocket: true,
      })
    );
  }
}
