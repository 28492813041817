import { ProductModel } from './product.model';

export class ManualCigarEntryModel {
  constructor(product?: ProductModel) {
    if (!product) {
      return;
    }

    this.Name = product.Name;
    this.Description = product.Description;
    this.LineId = product.LineId;
    this.ManufacturerValueId = product.Attributes.ManufacturerValueId;
    this.StrengthValueId = product.Attributes.StrengthValueId;
    this.OriginValueId = product.Attributes.OriginValueId;
    this.WrapperValueId = product.Attributes.WrapperValueId;
    this.BinderValueId = product.Attributes.BinderValueId;
    this.FillerValueId = product.Attributes.FillerValueId;
    this.WrapperColorValueId = product.Attributes.WrapperColorValueId;
    this.RollingTypeValueId = product.Attributes.RollingTypeValueId;
  }

  Name: string;
  Description: string;
  LineId: number;
  ManufacturerValueId: number;
  StrengthValueId: number;
  OriginValueId: number;
  WrapperValueId: number;
  BinderValueId: number;
  FillerValueId: number;
  WrapperColorValueId: number;
  RollingTypeValueId: number;
}
