import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import * as userSelectors from '@store/selectors/user';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard {
  constructor(private router: Router, private store: Store<IState>) {}

  canActivate(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store
      .select(userSelectors.selectIsAuthenticated)
      .pipe(take(1))
      .pipe(
        map((isAuthenticated) => {
          if (isAuthenticated) {
            this.router.navigate(['tabs', 'my-cigars']);
            return false;
          }
          return true;
        })
      );
  }
}
