import { selectAuthenticatedIuser } from './../../store/selectors/user';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoadingController } from '@ionic/angular/standalone';
import { IState, IUser } from '@models';
import { Store } from '@ngrx/store';
import { SocialService } from '@services/social.service';
import * as userSelectors from '@store/selectors/user';
import { finalize, first, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileResolver {
  constructor(
    private socialService: SocialService,
    private loadingCtrl: LoadingController
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IUser> {
    const loading = this.loadingCtrl.create({
      message: 'Loading user...',
      backdropDismiss: false,
    });
    loading.then((el) => el.present());

    return this.socialService
      .getUser(route.params['Id'], route.params['userType'])
      .pipe(
        finalize(() => {
          loading.then((el) => el.dismiss());
        })
      );
  }
}
