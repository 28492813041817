import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { AccountService } from '@services/account.service';
import * as userSelectors from '@store/selectors/user';
import { of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SuggestAccountGuard {
  firstLoad = true;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private store: Store<IState>
  ) {}

  canActivateChild(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (routeSnapshot.routeConfig.path === 'reset-password') {
      // we don't want suggestions if the user open reset-password page
      // without this we would be redirected to login page...
      return true;
    }

    return this.store.select(userSelectors.selectIsAuthenticated).pipe(
      first(),
      map((isAuthenticated) => {
        if (!this.firstLoad || isAuthenticated) {
          this.firstLoad = false;
          return true;
        } else {
          this.firstLoad = false;
          return this.accountService.suggestAccount().pipe(
            map((suggestedAccount) => {
              if (!suggestedAccount) {
                return true;
              }

              this.router.navigate(['login'], {
                queryParams: {
                  email:
                    suggestedAccount.IsCigarScanner ||
                    suggestedAccount.IsNeptune
                      ? suggestedAccount.Email
                      : null,
                  isNeptune: suggestedAccount.IsNeptune,
                  isFacebook: suggestedAccount.IsFacebook,
                },
              });

              return false;
            }),
            catchError((err) => {
              // avoid blank screen when opening app offline
              return of(true);
            })
          );
        }
      })
    );
  }
}
