import { Injectable } from '@angular/core';
import { IState } from '@models';
import { ISocketParsedMessage } from '@models/socket';
import { Store } from '@ngrx/store';
import * as userActions from '@store/actions/user';

import { SocketMessageModel } from '../../models/socket-message.model';
import { SocketEventHandlerInterface } from './socket-event-handler.interface';
import { IAccount } from '@models/account';

@Injectable()
export class UserSocketEventHandler implements SocketEventHandlerInterface {
  constructor(private store: Store<IState>) {}

  created(socketMessage: SocketMessageModel) {}

  deleted(socketMessage: SocketMessageModel) {}

  updated(socketMessage: ISocketParsedMessage<IAccount>) {
    const account = socketMessage.Data;
    this.store.dispatch(userActions.AccountSuccess({ account }));
  }
}
