import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Network } from '@capacitor/network';
import { environment } from '@environments/environment';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { OnlineService } from '@services/online.service';
import * as userActions from '@store/actions/user';
import debug from 'debug';
import _each from 'lodash-es/each';
import _trimEnd from 'lodash-es/trimEnd';
import { filter, tap } from 'rxjs';

import { SocketMessageModel } from '../models/socket-message.model';
import { DeviceService } from './device.service';
import { StorageService } from './storage.service';

declare let $: any;

export const GLOBAL_CHANNEL = 'Global';
export const PERSONAL_CHANNEL = 'Personal';

const LAST_SYNC_TIME_KEY = 'lastSyncTime';
const log = debug('cs:SocketHandlerService');

@Injectable({
  providedIn: 'root',
})
export class SocketHandlerService {
  private handlersMapping = {
    CigarLog: 'CigarLogSocketEventHandler',
    Humidor: 'HumidorSocketEventHandler',
    HumidorInfo: 'HumidorInfoSocketEventHandler',
    HumidorSensorMeasurement: 'SensorSocketEventHandler',
    HumidorSensorOffline: 'SensorOfflineSocketEventHandler',
    HumidorSensorGatewayResponse: 'GatewayResponseSocketEventHandler',
    Knowledge: 'KnowledgeSocketEventHandler',
    Cigar: 'ProductSocketEventHandler',
    Line: 'ProductSocketEventHandler',
    CigarRating: 'ProductReviewSocketEventHandler',
    CigarUserNote: 'ProductNoteSocketEventHandler',
    SocialPost: 'SocialPostSocketEventHandler',
    Account: 'UserSocketEventHandler',
    SocialComment: 'SocialCommentSocketEventHandler',
  };

  private connection;
  private messagesQueue = [];
  private blockedMessagesQueue = [];
  private syncInProgress = false;
  private allowReconnect = true;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceService,
    private injector: Injector,
    private storageService: StorageService,
    private store: Store<IState>,
    private onlineService: OnlineService
  ) {}

  start() {
    log('start');
    this.initConnection();

    this.onlineService.change
      .pipe(
        filter((e) => e.type === 'networkStatusChange'),
        tap(async (status) => {
          if (status.isOnline) {
            if (!this.connection) {
              this.initConnection();
            } else if (
              this.connection.state ===
                $.signalR.connectionState.disconnected &&
              this.allowReconnect
            ) {
              console.log('SocketHandler: Reconneting On Network Connect');
              this.establishConnection();
            }
          } else if (this.connection) {
            this.connection.stop();
          }
        })
      )
      .subscribe();

    $(window).focus(() => {
      console.log('SocketHandler: On Focus');

      if (
        this.connection &&
        this.connection.state === $.signalR.connectionState.disconnected &&
        this.allowReconnect
      ) {
        console.log('SocketHandler: Reconneting On Focus');
        this.establishConnection();
      }
    });
  }

  stop() {
    log('stop');
    try {
      if (this.connection) {
        this.allowReconnect = false;
        this.connection.stop();
        this.connection = null;
      }
    } catch (error) {}
  }

  private initConnection() {
    this.store.dispatch(userActions.AccountRequest());
  }

  public createConnection(userId = null) {
    this.connection = $.hubConnection(_trimEnd(environment.apiUrl, '/api'));
    this.allowReconnect = true;

    const queryParams = { UUID: this.deviceService.getDeviceID() };
    if (userId) {
      queryParams['UserId'] = userId;
    }
    this.connection.qs = queryParams;

    const syncHubProxy = this.connection.createHubProxy('Sync');
    syncHubProxy.on('SystemMessage', () => {});

    this.connection.error((error) => {
      console.error(`Web sockets connection error: ${error}`);
    });

    this.connection.reconnected(() => {
      this.syncMissedMessages();
    });

    this.connection.disconnected(() => {
      log('disconnected');
      // setTimeout(() => {
      //   if (
      //     this.connection &&
      //     this.connection.state === $.signalR.connectionState.disconnected &&
      //     this.allowReconnect
      //   ) {
      //     console.log('SocketHandler: Reconneting On Disconnected');
      //     this.establishConnection();
      //   }
      // }, 5000);
    });

    this.connection.received((data) => {
      this.addMessagesToQueue(data.A);
    });

    this.establishConnection();
  }

  private establishConnection() {
    this.connection
      .start({ transport: 'webSockets', jsonp: true }, () => {
        this.syncMissedMessages();
      })
      .done(() => {
        sessionStorage.connectionId = this.connection.id;
      });
  }

  private syncMissedMessages() {
    if (this.syncInProgress) {
      return;
    }
    this.syncStarted();

    const lastSyncTime = this.storageService.get(LAST_SYNC_TIME_KEY);
    if (lastSyncTime) {
      // if last sync is older than 3 days, refresh app
      if (this.isOlderThan(lastSyncTime, 3)) {
        this.storageService.clear();
      } else {
        this.http.get(`datasync?after=${lastSyncTime}`).subscribe(
          (data: any) => {
            if (data && data.SyncMessages) {
              // if we receive more that 50 messages, refresh app
              if (data.SyncMessages.length > 50) {
                this.storageService.clear();
              } else {
                this.addMessagesToQueue(data.SyncMessages, true);
              }
            }

            this.syncFinished();
          },
          (error) => {
            this.syncFinished();
          }
        );
      }
    } else {
      this.syncFinished();
      const now = new Date();
      this.saveLastSyncTime(now.toISOString());
    }
  }

  private syncStarted() {
    this.syncInProgress = true;
  }

  private syncFinished() {
    this.syncInProgress = false;

    // merge messages that appeared while sync was in progress (they are stored in blockedMessagesQueue)
    this.messagesQueue = this.messagesQueue.concat(this.blockedMessagesQueue);
    this.blockedMessagesQueue = [];
  }

  private addMessagesToQueue(messages: [any], force: boolean = false) {
    if (!this.syncInProgress || force === true) {
      // We will use queue because messages needs to be processed one by one.
      _each(messages, (msgData) => {
        this.messagesQueue.push(new SocketMessageModel(msgData));
      });

      this.processMessagesQueue();
    } else {
      // we will add messages to 'blocked' queue and merge them when sync if finished
      _each(messages, (msgData) => {
        this.blockedMessagesQueue.push(new SocketMessageModel(msgData));
      });
    }
  }

  // recursive function to process all queue messages
  private processMessagesQueue() {
    if (!this.messagesQueue.length) {
      return;
    }

    const message = this.messagesQueue.shift();
    this.handleMessage(message);
    this.saveLastSyncTime(message.CreatedOnUTC);
    this.processMessagesQueue();
  }

  private handleMessage(socketMessage: SocketMessageModel) {
    log('message', socketMessage);
    const handler = this.handlersMapping[socketMessage.Entity]
      ? this.injector.get(this.handlersMapping[socketMessage.Entity])
      : null;
    const action = socketMessage.Action.toLowerCase();

    if (handler && handler[action]) {
      handler[action](socketMessage);
    } else {
      console.error(
        'Handler not found for web socket message: ' +
          JSON.stringify(socketMessage)
      );
    }
  }

  private saveLastSyncTime(time) {
    this.storageService.set(LAST_SYNC_TIME_KEY, time);
  }

  private isOlderThan(lastSyncTime: string, days: number): boolean {
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - days);

    return new Date(lastSyncTime) < threeDaysAgo;
  }
}
