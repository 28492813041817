import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { ToastService } from '@services/toast.service';
import { from, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import * as userActions from '@store/actions/user';

import { environment } from '@environments/environment';
import { DeviceService } from '../services/device.service';
import { LocalStorageService } from '../services/local-storage.service';
import { extractErrorMsg } from '../utils';
import { getErrorMessage } from '@utils/errors';

const alertId = 'interceptor';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  alert: HTMLIonAlertElement;
  hasAnError = false;
  presented = false;
  constructor(
    private deviceService: DeviceService,
    private alertCtrl: AlertController,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
    private store: Store<IState>
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = prepareUrl(req.url);

    if (url.includes(environment.apiUrl)) {
      return from(this.localStorageService.get('authToken')).pipe(
        mergeMap((authToken) => {
          let headers = req.headers.set(
            'X-Device-UUID',
            this.deviceService.getDeviceID()
          );

          if (sessionStorage.connectionId) {
            headers = headers.set(
              'X-Device-RealtimeId',
              sessionStorage.connectionId
            );
          }

          if (authToken) {
            headers = headers.set('Authorization', `Bearer ${authToken}`);
          }

          const cloned = req.clone({
            headers: headers,
            url: url,
          });

          // this.requestService.setLastRequest(cloned.url, cloned.body);

          return next.handle(cloned).pipe(
            tap({
              error: async (err) => {
                if (err instanceof HttpErrorResponse) {
                  if (
                    err.status === 401 &&
                    !err.url.endsWith('account') &&
                    !err.url.endsWith('account/logout')
                  ) {
                    this.store.dispatch(userActions.Unauthorized());
                  } else if (err.status !== 401) {
                    await this.toastService.show(getErrorMessage(err));
                  }
                }
              },
            })
          );
        })
      );
    }

    return next.handle(req);
  }

  present(message) {
    this.hasAnError = true;
    this.alertCtrl
      .create({
        id: alertId,
        header: 'Error occurred',
        subHeader: message,
        buttons: ['OK'],
      })
      .then((alert) => {
        this.alert = alert;
        console.log('handleError', { alert });
        alert.onDidDismiss().then(() => {
          console.log('handleError onDidDismiss');
          this.hasAnError = false;
          this.presented = false;
        });
        return alert.present();
      })
      .then(() => {
        this.presented = true;
      });
  }
}

function prepareUrl(url: string) {
  const pattern = /^https?:\/\/|^\/\//i;

  if (!pattern.test(url)) {
    url = `${environment.apiUrl}/${url}`;
  }

  return url;
}
