import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginComponent } from '@components/login.component';
import { AppRoutes } from '@utils/routes';
import {
  IonRouterLink,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonFooter,
  IonButton,
} from '@ionic/angular/standalone';

@Component({
  selector: 'login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LoginComponent,
    IonRouterLink,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonFooter,
    IonButton,
  ],
  templateUrl: 'login.html',
  styleUrls: ['login.scss'],
})
export class LoginPage implements OnInit {
  email: string;
  isFacebook = false;
  isNeptune = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {}

  ionViewDidEnter() {
    const email = this.route.snapshot.queryParamMap.get('email');
    if (email) {
      this.email = email;
    }
    const isFacebook = this.route.snapshot.queryParamMap.get('isFacebook');
    if (isFacebook && isFacebook === 'true') {
      this.isFacebook = true;
    }
    const isNeptune = this.route.snapshot.queryParamMap.get('isNeptune');
    if (isNeptune && isNeptune === 'true') {
      this.isNeptune = true;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { email: null, isFacebook: null, isNeptune: null },
      queryParamsHandling: 'merge',
    });
  }
}
