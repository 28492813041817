import { AccountService } from '@services/account.service';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AlertController,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
} from '@ionic/angular/standalone';
import { ICigar, IState } from '@models';
import { Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { MatchPassword } from '@utils/form';
import { addIcons } from 'ionicons';
import { trashOutline } from 'ionicons/icons';
import { finalize, tap } from 'rxjs';
import { RxLet } from '@rx-angular/template/let';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RxLet,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonInput,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Update Password</ion-title>
        <ion-buttons slot="end">
          <ion-button
            fill="outline"
            shape="round"
            color="primary"
            (click)="submit()"
            [strong]="true"
            [autofocus]="false"
            [disabled]="!formGroup.dirty || isLoading"
          >
            Submit
          </ion-button>
        </ion-buttons>
        <ion-progress-bar type="indeterminate" *ngIf="isLoading">
        </ion-progress-bar>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <form [formGroup]="formGroup" (ngSubmit)="submit()" novalidate>
        <ion-list lines="full" class="ion-margin-bottom">
          <ion-item *rxLet="formGroup.get('Password'); let control">
            <ion-input
              label="Current Password *"
              labelPlacement="stacked"
              type="password"
              autocomplete="current-password"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : control.hasError('minlength')
                    ? 'Minimum 6 characters'
                    : ''
                  : ''
              "
            >
            </ion-input>
          </ion-item>

          <ion-item *rxLet="formGroup.get('NewPassword'); let control">
            <ion-input
              label="New Password *"
              labelPlacement="stacked"
              type="password"
              autocomplete="new-password"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : control.hasError('minlength')
                    ? 'Minimum 6 characters'
                    : ''
                  : ''
              "
            >
            </ion-input>
          </ion-item>
          <ion-item
            *rxLet="formGroup.get('NewPasswordConfirmation'); let control"
          >
            <ion-input
              label="Confirm New Password *"
              labelPlacement="stacked"
              type="password"
              autocomplete="new-password"
              [formControl]="control"
              [errorText]="
                control.touched
                  ? control.hasError('required')
                    ? 'Field is required'
                    : control.hasError('passwordMismatch')
                    ? 'Passwords do not match'
                    : ''
                  : ''
              "
            ></ion-input>
          </ion-item>
        </ion-list>
        <button type="submit" [hidden]="true">Submit</button>
      </form>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPasswordModal implements OnInit {
  isLoading = false;

  formGroup = this.fb.nonNullable.group(
    {
      Password: ['', [Validators.required, Validators.minLength(6)]],
      NewPassword: ['', [Validators.required, Validators.minLength(6)]],
      NewPasswordConfirmation: [
        '',
        [Validators.required, Validators.minLength(6)],
      ],
    },
    {
      validators: [MatchPassword('NewPassword', 'NewPasswordConfirmation')],
    }
  );

  constructor(
    protected store: Store<IState>,
    private modalService: ModalService,
    private fb: FormBuilder,
    private accountService: AccountService,
    private cdr: ChangeDetectorRef,
    private alertCtrl: AlertController
  ) {
    addIcons({ trashOutline });
  }

  ngOnInit() {}

  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    this.formGroup.disable();
    this.cdr.markForCheck();
    const value = this.formGroup.value;
    this.accountService
      .update(value)
      .pipe(
        tap({
          next: () => {
            this.modalService.dismiss(this.formGroup.value, 'success');
          },
        }),
        finalize(() => {
          this.isLoading = false;
          this.formGroup.enable();
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }
}
