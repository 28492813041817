import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  IonApp,
  IonContent,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  MenuController,
  Platform,
} from '@ionic/angular/standalone';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { ModalsService } from '@services/modals.service';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import { DeviceService } from '@shared/services/device.service';
import * as userActions from '@store/actions/user';
import * as notificationsSelectors from '@store/selectors/notifications.selectors';
import * as userSelectors from '@store/selectors/user';
import { addIcons } from 'ionicons';
import {
  chatbubbles,
  hammer,
  notifications,
  settings,
  shareSocial,
  tv,
} from 'ionicons/icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RxLet,
    CreateSourceStandalonePipe,
    UserNamePipe,
    IonApp,
    IonMenu,
    IonItem,
    IonLabel,
    IonSplitPane,
    IonContent,
    IonMenuButton,
    IonMenuToggle,
    IonRouterOutlet,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  // activeUser: UserModel;
  mobile: boolean;
  private _unsubscribeAll: Subject<void> = new Subject();

  unread$ = this.store.select((state) =>
    notificationsSelectors.selectUnread(state)
  );
  account$ = this.store.select(userSelectors.selectAccount);
  isAuthenticated$ = this.store.select(userSelectors.selectIsAuthenticated);
  isKiosker$ = this.store.select(userSelectors.selectIsKiosker);

  constructor(
    private store: Store<IState>,
    private modalsService: ModalsService,
    private menuCtrl: MenuController,
    public platform: Platform,
    private breakpointService: DeviceService
  ) {
    this.breakpointService.isMobile$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isMobile) => (this.mobile = isMobile));
    addIcons({ tv, settings, hammer, notifications, shareSocial, chatbubbles });
  }

  ngOnInit() {
    // this.activeUser = this.activeUserService.user();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openSearch() {
    this.modalsService.addFromSearch();
  }

  logout() {
    this.menuCtrl.close();
    this.store.dispatch(userActions.LogoutRequest());
  }

  takePicture() {
    this.modalsService.addFromCamera();
  }

  rateApp() {
    if (this.platform.is('android')) {
      // we do not want to open this in app but use the default browser
      window.open(
        'https://play.google.com/store/apps/details?id=com.ionicframework.cigarscanner238202&hl=en',
        '_blank',
        'location=yes'
      );
    }
  }
}
