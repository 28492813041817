import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import debug from 'debug';
import { filter, Subject, tap } from 'rxjs';
import { OnlineService } from './online.service';

const log = debug('cs:NetworkService');

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  onConnect = new Subject();
  private alertPromise?: Promise<HTMLIonAlertElement>;

  constructor(
    public alertController: AlertController,
    private onlineService: OnlineService
  ) {}

  async init() {
    log('init');
    this.onlineService.change
      .pipe(
        filter((e) => e.type === 'networkStatusChange'),
        tap((e) => this.handleAlert(e.isOnline))
      )
      .subscribe();
  }

  async handleAlert(isOnline: boolean) {
    console.log('handleAlert', isOnline);
    log('Network status changed', status);
    if (isOnline) {
      log('network connected!');
      if (this.alertPromise) {
        await (await this.alertPromise).dismiss();
        delete this.alertPromise;
      }
    } else if (!this.alertPromise) {
      log('network was disconnected :-(');
      this.alertPromise = this.alertController.create({
        header: 'Please check your Internet connection',
        message:
          'Cigar Scanner only works with an active Internet connection. Thank you!',
        backdropDismiss: false,
        keyboardClose: false,
      });
      await (await this.alertPromise).present();
    }
  }
}
